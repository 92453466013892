import React, { useState,useContext } from "react";
import './Nav.css'
import { Link,NavLink } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { IoMdLogOut } from "react-icons/io";
import lg from "../../Asserts/lg.png"

function Navbar(){

    const [menuOpen,setMenuOpen] = useState(false)
    const {user,Logout} = useContext(AuthContext)
    
   async function handleLogout(){
    await Logout()
     }

    return(
      
           <div className="contLupa">

            <div>
            <Link to='/Home'><img src={lg} width={130}/></Link>
            </div>
           <div className="cn">
           <a onClick={Logout}><IoMdLogOut color="#FFF"/></a>
           </div>

     
           
         
       
          </div>
    )
}

export default Navbar;