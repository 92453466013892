import React from 'react'
import './App.css'
export default function Termos() {
 return (
   <div className='contTermos'>
    <h4>TERMOS DE USO — ViewerSpy</h4>
    <p>
    
<b>ViewerSpy</b>, Pessoa jurídica privada descreve, por meio deste documento, as regras de utilização do site espectadorspy.com e de qualquer outro site, loja ou aplicativo operado pelo proprietário.

Ao navegar neste site, consideramos que você concorda com os Termos de Uso abaixo.

Caso não concorde com os termos deste acordo, pedimos-lhe que deixe de utilizar este website, muito menos de se registar ou de submeter os seus dados pessoais.
Caso alteremos nossos Termos de Uso, publicaremos o novo texto neste site, com a data de revisão atualizada. Poderemos alterar este documento a qualquer momento. Se houver uma alteração material nos termos deste contrato, poderemos notificá-lo usando as informações de contato que temos em nosso banco de dados ou por meio de notificação.
O uso deste site após as alterações significa que você aceita os Termos de Uso revisados. Se, após ler a versão revisada, você não concordar com seus termos, cancele seu acesso.
<br></br><br></br><b>Seção 1 - Usuário</b><br></br><br></br>
A utilização deste website atribui-lhe automaticamente o estatuto de Utilizador e implica a aceitação integral de todas as diretrizes e condições incluídas nestes Termos.
<br></br><br></br><b>Seção 2: Associação junto com Política de Privacidade</b><br></br><br></br>
O uso deste site constitui conformidade com estes Termos de Uso e com a versão mais atual da Política de Privacidade do ViewerSpy.
<br></br><br></br><b>Seção 3 - Condições de acesso</b><br></br><br></br>
Em geral, o acesso ao site ViewerSpy é gratuito e não requer cadastro ou cadastro prévio.
Porém, para usufruir de algumas funcionalidades, o usuário pode precisar se cadastrar, criando uma conta de usuário com nome de usuário e senha próprios para acesso.
É de exclusiva responsabilidade do usuário fornecer apenas informações corretas, autênticas, válidas, completas e atualizadas, bem como não divulgar seu nome de usuário e senha a terceiros.Partes deste site oferecem ao usuário a opção de postar comentários em determinadas áreas. ViewerSpy não tolera a publicação de conteúdo de natureza discriminatória, ofensiva ou ilegal, ou que infrinja direitos autorais ou quaisquer outros direitos de terceiros.

A publicação de qualquer conteúdo pelo usuário deste site, incluindo mensagens e comentários, implica licença não exclusiva, irrevogável e irretratável para seu uso, reprodução e publicação pela ViewerSpy em seu site, plataformas e aplicações de Internet, ou em outras plataformas. sem qualquer restrição ou limitação.
<br></br><br></br><b>Seção 4 - Cookies</b><br></br><br></br>Informações sobre o uso deste site podem ser coletadas através de cookies. Cookies são informações armazenadas diretamente no computador que você está usando. Os cookies permitem-nos recolher informações como tipo de navegador, tempo passado no site, páginas visitadas, preferências de idioma e outros dados de tráfego anónimos. Nós e nossos prestadores de serviços utilizamos informações para proteger a segurança, facilitar a navegação, exibir informações de forma mais eficiente e personalizar sua experiência ao usar este site, bem como para rastreamento online. Também recolhemos informações estatísticas sobre a utilização do website para melhorar continuamente o nosso design e funcionalidade, compreender como o website é utilizado e ajudar-nos a resolver problemas relevantes.

Se você não deseja que suas informações sejam coletadas através de cookies, existe um procedimento simples na maioria dos navegadores que permite rejeitar cookies automaticamente ou oferece a opção de aceitar ou rejeitar a transferência de um cookie (ou cookies) específico. de um determinado site para o seu computador. No entanto, isso pode causar inconvenientes ao usar o site.

As configurações que você escolher podem afetar a sua experiência de navegação e o desempenho exigido pelo uso de cookies. Neste sentido, rejeitamos qualquer responsabilidade pelas consequências decorrentes do funcionamento limitado deste site causado pela desativação de cookies no seu dispositivo (incapacidade de definir ou ler um cookie).
<br></br><br></br><b>Seção 5 - Propriedade intelectual</b><br></br><br></br>
Todos os elementos do ViewerSpy são propriedade intelectual do ViewerSpy ou de seus licenciadores. Estes Termos ou o uso do Site não concedem a você nenhuma licença ou direito de usar quaisquer direitos de propriedade intelectual do ViewerSpy ou de terceiros.
<br></br><br></br><b>Seção 6 - Links para sites de terceiros</b><br></br><br></br>
Este website poderá, ocasionalmente, conter links de hipertexto que o redirecionarão para os websites das nossas redes parceiras, anunciantes, fornecedores, etc. Se você clicar em um desses links para qualquer um desses sites, lembre-se de que cada site tem suas próprias práticas de privacidade e não somos responsáveis ​​por essas políticas. Revise essas políticas antes de enviar quaisquer dados pessoais a esses sites.

Não somos responsáveis ​​pelas políticas e práticas de coleta, uso e divulgação (incluindo práticas de proteção de dados) de outras organizações, como Facebook, Apple, Google, Microsoft ou qualquer outro desenvolvedor de software ou fornecedor de aplicativos, lojas de mídia, redes sociais, operadoras sistemas. , provedor de serviços de Internet sem fio ou fabricante de dispositivos, incluindo quaisquer dados pessoais que você divulgue a outras organizações por meio dos aplicativos, em conexão com tais aplicativos ou publicados em nossas páginas de mídia social. Recomendamos que você se informe sobre a política de privacidade e os termos de uso de cada site que você visita ou provedor de serviços que utiliza.
<br></br><br></br><b>Seção 7 - Prazos e modificações</b><br></br><br></br>
Este site funcionará por tempo indeterminado.

O site na sua totalidade ou em cada uma de suas seções poderá ser encerrado, suspenso ou interrompido unilateralmente pela ViewerSpy, a qualquer momento e sem aviso prévio.
<br></br><br></br><b>Seção 8 – Dados pessoais</b><br></br><br></br>
Durante o uso deste site, o ViewerSpy e/ou os Parceiros coletarão e processarão determinados dados pessoais. As regras relativas ao tratamento de dados pessoais pelo ViewerSpy estão estipuladas na Política de Privacidade.
<br></br><br></br><b>Seção 9 - Contato</b><br></br><br></br>
Se você tiver alguma dúvida sobre os Termos de Uso, entre em contato conosco pelo e-mail: <b>appviewersspy@gmail.com</b>.
  <br></br> <br></br><br></br> </p>
   </div>
  );
}