import React, { useContext, useEffect, useId, useState } from 'react';
import './App.css'
import {AuthContext} from '../contexts/auth';
import {db} from '../firebase'
import {doc,getDoc, onSnapshot} from 'firebase/firestore'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';


const containerStyle = {
  width: '400px',
  height: '100%',
  
};

export default function Maps() {
  const {user} = useContext(AuthContext)
  const [ lat,setLat] = useState('')
  const [ lng,setLng] = useState('')

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCSUayRckWVV0r9oenkLQG4JEGlLt1O9MI"
  })

  useEffect(()=>{
   async function dads(){
    const ref  = onSnapshot(doc(db, "users", `${user.uid}`), (dcsnap)=>{
      setLat(dcsnap.data().latitude)
      setLng(dcsnap.data().longitude)
      }
      )




 
       }
       dads()

  },[])

 return (
 <div style={{ height: '100vh', width: '100%' }}>
     {isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat:lat,
          lng:lng
        }}
        zoom={100}
        
        
     
      >
       <Marker position={{lat:lat,lng:lng}} />
      </GoogleMap>
     ) : (
      <></>
     )

     }
    </div>
  );
}