import { Link,useNavigate } from "react-router-dom";
import "./App.css"


import React ,{useState,useEffect} from "react";
import { FaCheckSquare } from "react-icons/fa";
import lg from "../../src/Asserts/lg.png"
import {toast, ToastContainer} from 'react-toastify'
import {auth,db} from '../firebase'
import { onSnapshot,doc } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
export default function Home() {
  const [status,setStatus] = useState(false)
  const [email,setEmail] = useState('')
  const [valorP,setValorP] = useState("0")
  const [show, setShow] = useState(false);
  const [aviso, setAviso] = useState("");
  const [avise, setAvise] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);




async function handleCheckout(){

 /* const stripe = await loadStripe('pk_test_51OaXS4CXPtVkQR0ddKyAGEhEvTCBwoYpxCdcFvjuxhSbCgtsNVVJP74renELWHKp9IRGDudADJkJrXBIcoKdnoRH00O1y9RQO9');
  const { error } = await stripe.redirectToCheckout({
    lineItems:[
      {
        price:'price_1PRgHZCXPtVkQR0dtvhgOMe2',
        quantity:1,
      },
    ],
    mode: 'subscription',
    successUrl: 'https://viewerspy.netlify.app/sucess/{CHECKOUT_SESSION_ID}',
    cancelUrl: 'https://viewerspy.netlify.app/cancel',
    customerEmail:`${email}`
  })
 
  console.warn(error.message)
*/
/*
 if(valorP=="59"){

  window.open('https://go.perfectpay.com.br/PPU38COP9AG')


 }
 else if(valorP==139){
 
  window.open('https://go.perfectpay.com.br/PPU38COP97N')
 }
 else if(valorP==329){
 
  window.open('https://go.perfectpay.com.br/PPU38COP98N')

 }

*/
}

  
  useEffect(()=>{
    const dados = JSON.parse(localStorage.getItem("@tricretPro"))
   
   const stat = async () =>{
    const unsub = onSnapshot(doc(db, "users", `${dados.uid}`), (doc) => {
      console.log("Current data: ", doc.data());
      setEmail(doc.data().email)
   })
  }

  const avisos = async () =>{
    const avisoss = onSnapshot(doc(db, "avisos", `atualizacao`), (doc) => {
      console.log("Current data: ", doc.data());
      setAviso(doc.data().mensagem)
     
   })
  }
avisos()
  stat()
  handleShow()

  },[])





  return (
  
    <div className="hmCont">
      <div className="sep"></div>
<div className="boxavisos">
  <span>AVISO</span>
 
{
        aviso && <p>{aviso}</p>
      }
</div>
     

    <Link to="/maps">Localização</Link>
    <Link to="/galeria">Galeria</Link>

    <Modal variant='box' show={show} onHide={handleClose}>
   
        <Modal.Header closeButton>
          <Modal.Title >Como instalar o aplicativo!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        Baixe o aplicativo e veja o vidéo 
        e veja como funciona todo o processo de instalação.
          <br/><br/>
          <h4>Aplicativo</h4>
          <a href="https://drive.google.com/file/d/1ME8OUUx-hyu2KAWhD4f8U9ZadAvdXVAY/view?usp=drivesdk">Baixar Aplicativo viewerspy</a>
          <br/><br/>
          <h4>Vídeo explicativo</h4>
          <a href="https://drive.google.com/file/d/1Pz5wmskHFSn2Q-liLV10JHsHtslnoyOx/view?usp=drivesdk">Assistir vídeo</a>
          </Modal.Body>
          
        <Modal.Footer>
          
          viewerspy
        </Modal.Footer>
      </Modal>


   </div>
   );
}

