import { useContext, useEffect,useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import { onAuthStateChanged } from 'firebase/auth'
import Navbar from '../Components/NavBar'
import { auth } from "../firebase";

function Private({children}){
    const { signed,loading } = useContext(AuthContext)
   
    
    useEffect(()=>{

        async function checkLogin(){
            const usb =  onAuthStateChanged(auth, (user) => {
                if(user){

                    const userData = {
                        uid:user.uid,
                        email:user.email
                    }

                    localStorage.setItem("@detailUser", JSON.stringify(userData))
                 

                }else{
                    
                }
            })
        }
         

checkLogin()


    },[])

    if(loading){
        return <div></div>
    }

    if(!signed){
        
        return <Navigate to="/"/> 
       
       
    }
        return children;
   
}

export default Private