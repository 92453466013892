import './App.css'
import React, { useEffect,useContext, useState } from "react";

import {storage} from "../../src/firebase"
import {ref, list} from "firebase/storage"
import {AuthContext} from '../contexts/auth';
import { m } from "framer-motion";

export default function Galeria() {
  const {user} = useContext(AuthContext)
 
  const [url,setUrl] = useState([])
  useEffect(()=>{

    async function videos(){
      const lista = await ref(storage, `videos/${user.uid}`) 

    const page = await list(lista,{maxResults:100})
    setUrl(page.items)
    console.log(page.items)
  
    }
    videos()

  },[])

 return (
   <div className='cntVdeu'>
  {
    url.map((f)=>{
      return(
        <div>
<video className="video_play" controls="controls">
<source src={`https://firebasestorage.googleapis.com/v0/b/cs-canalsilvio.appspot.com/o/videos%2F${f._location.path_.split('/')[1]}%2F${f.name}?alt=media&token=fcf17ac0-8631-4641-97b6-1b1030183e1e`} type="video/mp4"/>
</video>
      </div>
      )
    })
  }
   

   </div>
  );
}