import React, { useContext, useId, useState } from 'react';
import {AuthContext} from '../contexts/auth';
import "./App.css"
import { toast } from 'react-toastify';
import lg from '../Asserts/lg.png' 
import { NavLink } from 'react-router-dom';



function SingIn() {
  const [username,setUsername] = useState("")
  const [pw,setPw] = useState("")
  const {singIn} = useContext(AuthContext)



  useState(()=>{
   
   
  })

  async function handlelogin(e){
    e.preventDefault();
    if(username !== "" && pw !== ""){
    await singIn(username,pw)
 
    }
    else if(username == "" && pw == ""){
      toast.error('Preencha os campos com e-mail e senha!')
    }
    else if(username == ""){
      toast.error('Preencha o campo de login!')
    }
    else if(pw == ""){
      toast.error('Preencha o campo de senha!')
    }
  }


  return (
    <div className="container-center">
  
      <div className='login'>
        <div className='login-area'>
          <img src={lg}  />
         
        </div>
        
     
        <form onSubmit={handlelogin}>
        

          <input type='text' 
          placeholder='E-mail' 
          value={username} 
          onChange={(e)=>setUsername(e.target.value)}
          />

          <input 
          type='password'
          placeholder='Senha'
          value={pw}
          onChange={(e)=>setPw(e.target.value)}
          
          />

        <button type='submit'>Entrar</button>
        </form>
        <div className='space'></div>
        <NavLink to='singup'>Criar uma conta</NavLink>
        <NavLink to='recuperar'>Recuperar senha</NavLink>

      </div>
     
    </div>
  );
}

export default SingIn;
