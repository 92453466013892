import './App.css'
import React, { useContext, useId, useState } from 'react';
import {AuthContext} from '../contexts/auth';

import { toast } from 'react-toastify';
import lg from '../Asserts/lg.png' 
import { Link } from 'react-router-dom';



function Recuperar() {
  const [email,setEmail] = useState("")
  const [pw,setPw] = useState("")
  const {Recuperarsenha} = useContext(AuthContext)
  const [check,setCheck] = useState(false)



  useState(()=>{
   
   
  })

  async function reupere(e){
    e.preventDefault();
    if(email !== ""){
     await Recuperarsenha(email)
 
    }
    else if(email == ""){
      toast.error('Preencha o campo com seu email!')
    }
   

  }


  return (
    <div className="container-center">
  
      <div className='login'>
        <div className='login-area'>
          <img src={lg}  />
         
        </div>
        
        <h1>Insira seu email abaixo!</h1>
     
        <form onSubmit={reupere}>
        

          <input type='text' 
          placeholder='E-mail' 
          value={email} 
          onChange={(e)=>setEmail(e.target.value)}
          />

          
         
        <button type='submit'>Recuperar senha</button>
        </form>

      </div>
     
    </div>
  );
}

export default Recuperar;
