import { Route, Routes } from "react-router-dom"


import SingIn from "../SingIn";
import Singup from "../Singup";
import Termos from "../Termos";
import Navbar from "../Components/NavBar";
import Private from "./Private";
import Home from "../Home";
import Galeria from "../Galeria";
import Maps from "../Maps"
import Sucess from '../Sucess'
import Recuperar from '../recuperar'







function RoutesApp(){
   return(

     
      <Routes>

   <Route path="/"   element = { <SingIn/> } />
   <Route path="/recuperar"   element = { <Recuperar/> } />
   <Route path="/sucess" exact  element = {<Sucess/>}  />
   <Route path="/singup"   element = { <Singup/> } />
   <Route path="/termos" element = { <Termos/> } /> 
  

   <Route path="/home"  element = {<Private><Navbar/><Home/></Private> }  />
   <Route path="/galeria"  element = {<Private><Navbar/><Galeria/></Private> }  />
   <Route path="/maps"  element = {<Private><Navbar/><Maps/></Private> }  />

 
      </Routes>
     
     
   )
}

export default RoutesApp;